import { Schema } from '../schemas';
import BaseComponent from './BaseComponent';

class RootComponent extends BaseComponent {
  constructor(context = {}) {
    super(document.body, Schema.Root(), context);
  }

  exist() {
    return !!this._node;
  }
}

export default RootComponent;
