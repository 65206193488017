import { MissingEventCallbackError } from '../errors';

function createEventBus() {
  let eventObject = new Map();

  // Subscribe to events
  function addEventListener(eventName, callback) {
    if (!callback) throw new MissingEventCallbackError(eventName);

    if (!eventObject.has(eventName)) {
      eventObject.set(eventName, new Set());
    }

    eventObject.get(eventName).add(callback);
  }

  // Remove event listener
  function removeEventListener(eventName, callback) {
    if (!callback) throw new MissingEventCallbackError(eventName);

    if (!eventObject.has(eventName)) {
      return;
    }

    const eventListeners = eventObject.get(eventName);
    eventListeners.delete(callback);

    if (eventListeners.size === 0) {
      eventObject.delete(eventName);
    }
  }

  // Emit event
  function emit(eventName, ...payload) {
    const callbacks = eventObject.get(eventName);
    if (!callbacks || callbacks.size === 0) return;

    callbacks.forEach((c) => {
      c(...payload);
    });
  }

  return {
    addEventListener,
    removeEventListener,
    emit,
  };
}

const eventBus = createEventBus();

export default eventBus;
