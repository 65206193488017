import RootComponent from './RootComponent';
import { findSubComponents } from './parsing/attributeComponent';
import Component from './Component';
import CollectionComponent from './CollectionComponent';

function withContext(ctx) {
  return new RootComponent(ctx);
}

export { findSubComponents, withContext, Component, CollectionComponent };
