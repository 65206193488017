export function getCurrentPageIdentifier(appExtensionSdkData) {
  return appExtensionSdkData?.pageIdentifier;
}

let installedAppIds = null;
export function getInstalledAppIds(appExtensionSdkData) {
  if (!installedAppIds) {
    installedAppIds = appExtensionSdkData?.appExtensionData?.appIds || [];
  }
  return installedAppIds;
}
