import Logger from './utils/logger';
import { withContext } from './universalComponent';
import eventBus from './utils/eventBus';
import { utilsFunctions } from './utils/setup';
import { getInstalledAppIds } from './helpers/getData';
import { InvalidAppIdError } from './errors';

function validateOptions(options) {
  const installedAppIds = getInstalledAppIds();
  if (!installedAppIds.includes(options?.appId)) {
    throw new InvalidAppIdError();
  }
}

function init(options) {
  validateOptions(options);

  const logger = new Logger(options.appId);

  const universalComponent = withContext(options);

  const utils = Object.entries(utilsFunctions).reduce(
    (acc, [funcName, func]) => {
      acc[funcName] = async (...params) => {
        try {
          return await func(options, ...params);
        } catch (err) {
          logger.log(`Err from ${funcName}:`, err);
          throw err;
        }
      };
      return acc;
    },
    {},
  );

  // Should not expose emit functions to public
  const events = {
    addEventListener: eventBus.addEventListener,
    removeEventListener: eventBus.removeEventListener,
  };

  return {
    universalComponent,
    utils,
    events,
    logger,
  };
}

export { init };
