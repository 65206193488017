import eventBus from './eventBus';

function _listenToAngularEvents(ele, incomingEvent, sdkEvents) {
  ele?.$on(incomingEvent, (event, ...payload) => {
    for (const sdkEvent of sdkEvents) {
      eventBus.emit(sdkEvent, ...payload);
    }
  });
}

function _setupEvent(dependencies, schema) {
  switch (schema.type) {
    case 'angular': {
      const angularEle = dependencies.angular?.[schema.source];
      _listenToAngularEvents(
        angularEle,
        schema.incomingEvent,
        schema.sdkEvents,
      );
      break;
    }
    default:
      break;
  }
}

function _setupEvents(dependencies, eventsSchema) {
  if (!eventsSchema) return {};

  for (const eventSchema of eventsSchema) {
    _setupEvent(dependencies, eventSchema);
  }
}

export { _setupEvents };
