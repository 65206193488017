export default {
  functions: [
    {
      name: 'getCPProducts',
      type: 'angularData',
      options: {
        source: 'appExtensionSdkData',
        key: 'plpProducts',
      },
    },
  ],
};
