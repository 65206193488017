class Logger {
  constructor(appId) {
    this.appId = appId;
  }

  log(...messages) {
    console.log(`[Storefront SDK (AppId: ${this.appId})]:`, ...messages);
  }
}

export default Logger;
