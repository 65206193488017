import { Component, findSubComponents } from '../components';

class Schema {
  constructor(name, options = {}) {
    this.name = name;
    this.options = options;
    this.subschemas = {};
  }

  static Root() {
    if (!this._root) {
      this._root = new Schema('root', {});
    }
    return this._root;
  }

  register(name, schema, callback = null) {
    this.subschemas[name] = schema;
    callback?.(schema);
    return this;
  }

  inspect(name) {
    return this.subschemas[name];
  }

  select(node, componentContext) {
    const subNode = findSubComponents(node, this.name)[0];
    return new Component(subNode, this, componentContext);
  }
}

export default Schema;
