import NullComponent from './NullComponent';

class BaseComponent {
  constructor(node, schema, context = {}) {
    this._node = node;
    this._schema = schema;
    this._context = context;
  }

  get(name) {
    if (!this._schema?.subschemas?.[name]) return new NullComponent();
    const subComponent = this._schema.subschemas[name].select(
      this._node,
      this._context,
    );
    if (!subComponent.exist()) return new NullComponent();
    return subComponent;
  }

  list() {
    return Object.keys(this._schema.subschemas);
  }

  exist() {
    return !!this._node && !!this._node.begin && !!this._node.end;
  }
}

export default BaseComponent;
