import { ComponentNotFoundError } from '../../errors';

class NullComponent {
  constructor() {
    this._node = null;
    this._schema = null;
    this._context = null;
  }

  get() {
    return this;
  }

  list() {
    return [];
  }

  exist() {
    return false;
  }

  mount() {
    throw new ComponentNotFoundError();
  }

  unmount() {
    throw new ComponentNotFoundError();
  }

  hide() {
    throw new ComponentNotFoundError();
  }

  unhide() {
    throw new ComponentNotFoundError();
  }
}

export default NullComponent;
