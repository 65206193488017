import Schema from './Schema';
import { findSubComponents, CollectionComponent } from '../components';

class CollectionSchema extends Schema {
  select(node, componentContext) {
    const subNodes = findSubComponents(node, this.name);
    return new CollectionComponent(subNodes, this, componentContext);
  }
}

export default CollectionSchema;
