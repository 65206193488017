import BaseComponent from './BaseComponent';
import Component from './Component';

class CollectionComponent extends BaseComponent {
  // this._node is an array for CollectionComponent

  get(index) {
    return new Component(this._node[index], this._schema, this._context);
  }

  list() {
    return [...Array(this._node.length).keys()];
  }

  exist() {
    return !!this._node && !!this._node.length;
  }
}

export default CollectionComponent;
