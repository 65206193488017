import schemaDefinitions from './schemasDefinitions';
import { CollectionSchema, Schema } from './schemas';
import { withContext } from './components';

function _createSchema(name, options = {}) {
  if (options.collection) {
    return new CollectionSchema(name, options);
  } else {
    return new Schema(name, options);
  }
}

function _registerSchema(currentPage) {
  const currentPageSchemaDefinitions = schemaDefinitions[currentPage] || [];
  for (let definition of currentPageSchemaDefinitions) {
    let schema = _createSchema(definition.name, definition.options);

    Schema.Root().register(
      definition.name,
      schema,
      _registerCallback(definition.children),
    );
  }
}

function _registerCallback(definitions) {
  if (!definitions || definitions.length === 0) return undefined;
  return (ele) => {
    for (let definition of definitions) {
      if (definition.children) {
        ele.register(
          definition.name,
          _createSchema(definition.name, definition.options),
          _registerCallback(definition.children),
        );
      } else {
        ele.register(
          definition.name,
          _createSchema(definition.name, definition.options),
        );
      }
    }
  };
}

// Initialize SDK
function _initializeUniversalComponent(currentPage) {
  _registerSchema(currentPage);
}

// Expose SDK
export { withContext, _initializeUniversalComponent };
