const attributeName = 'sl-component';
const attributeNameBegin = 'sl-component-begin';
const attributeNameEnd = 'sl-component-end';
const SKIP_COMMENTS_BEGIN = [
  'ngIf',
  'ngRepeat',
  'ngSwitchWhen',
  'ngSwitchDefault',
  'ngInclude',
];
const SKIP_COMMENTS_END = ['end ngIf', 'end ngRepeat', 'end ngSwitchWhen'];

function findSubComponents($ctx, name) {
  if ($ctx.nodeType === Node.ELEMENT_NODE) {
    // case 1: $ctx is normal dom node
    // find all anchored components first
    const anchors = _findAnchor($ctx, name);

    // Get the begin & end positions
    return _getPositions(anchors);
  }

  // case 2-1: $ctx is begin-end range and only has 1 node within the range
  if ($ctx.begin === $ctx.end) {
    // case 2-1: has original component
    let parentNode = $ctx.begin;
    if (parentNode.nodeType !== Node.ELEMENT_NODE) {
      // There will be no elements inside the range, return empty array
      return [];
    }
    const anchors = _findAnchor(parentNode, name);

    return _getPositions(anchors);
  }

  // case 2-2: $ctx is begin-end range and has 1 / multiple nodes in the range
  let currNode = $ctx.begin;
  if (currNode.nodeType !== Node.ELEMENT_NODE) {
    currNode = currNode.nextElementSibling;
  }

  let endNode = $ctx.end;
  if (endNode.nodeType !== Node.ELEMENT_NODE) {
    endNode = endNode.previousElementSibling;
  }
  let anchors = [];
  if (currNode !== null) {
    do {
      if (currNode.getAttribute(attributeName) === name) {
        anchors.push({ begin: currNode, end: currNode });
      } else if (currNode.getAttribute(attributeNameBegin) === name) {
        anchors.push({
          begin: currNode,
          end: _findSiblingEndAnchor(currNode, name),
        });
      }
      // also traverse node
      anchors.push(..._findAnchor(currNode, name));

      currNode = currNode.nextElementSibling;
    } while (currNode !== null && currNode !== endNode?.nextElementSibling);
  }

  return _getPositions(anchors);
}

function _findAnchor(parent, name) {
  // Get the elements with the sl component attributes
  const directAnchors = Array.from(
    parent.querySelectorAll(`[${attributeName}="${name}"]`),
  ).map((ele) => ({ begin: ele, end: ele }));

  const rangeAnchors = Array.from(
    parent.querySelectorAll(`[${attributeNameBegin}="${name}"]`),
  ).map((ele) => {
    return {
      begin: ele,
      end: _findSiblingEndAnchor(ele, name),
    };
  });

  const anchors = rangeAnchors.concat(directAnchors);

  return anchors;
}

function _findSiblingEndAnchor(begin, name) {
  // Get the anchor with sl-component-end attribute given the starting anchor
  if (begin.getAttribute(attributeNameEnd) === name) return begin;

  let end = begin.nextElementSibling;
  while (end !== null) {
    if (end.getAttribute(attributeNameEnd) === name) break;
    end = end.nextElementSibling;
  }

  return end;
}

function _getPositions(elements) {
  return elements.map((ele) => {
    const beginPosition = _getBeginPosition(ele.begin);
    const endPosition = _getEndPosition(ele.end);

    return {
      begin: beginPosition,
      end: endPosition,
    };
  });
}

function _shouldSkipSiblingCheck(ele, skipComments) {
  const trimmedContent = ele.nodeValue.trim();
  if (ele.nodeType === Node.TEXT_NODE && trimmedContent.length > 0) {
    // No need to continue if node is text node & is not empty
    return false;
  }
  if (ele.nodeType === Node.COMMENT_NODE) {
    // No need to continue if node is comment node & not related to angularjs comments
    const needToSkip = skipComments.some((skippableComment) =>
      trimmedContent.startsWith(skippableComment),
    );
    if (!needToSkip) return false;
  }

  return true;
}

function _isNotNullNorElementNode(ele) {
  return ele !== null && ele.nodeType !== Node.ELEMENT_NODE;
}

function _getBeginPosition(ele) {
  // Skip the angularjs comments and return the correct position
  let prev = ele;
  while (_isNotNullNorElementNode(prev.previousSibling)) {
    if (!_shouldSkipSiblingCheck(prev.previousSibling, SKIP_COMMENTS_BEGIN)) {
      break;
    }

    prev = prev.previousSibling;
  }

  return prev;
}

function _getEndPosition(ele) {
  // Skip the angularjs comments and return the correct position
  let next = ele;
  while (_isNotNullNorElementNode(next.nextSibling)) {
    if (!_shouldSkipSiblingCheck(next.nextSibling, SKIP_COMMENTS_END)) {
      break;
    }

    next = next.nextSibling;
  }

  return next;
}

export { findSubComponents };
