const schema = [
  {
    name: 'product-info',
    children: [
      {
        name: 'variation-selector',
      },
    ],
  },
];

export default schema;
