export default {
  functions: [
    {
      name: 'getODPOrder',
      type: 'angularData',
      options: {
        source: 'appExtensionSdkData',
        key: 'odpOrder',
      },
    },
    {
      name: 'getODPProducts',
      type: 'angularData',
      options: {
        source: 'appExtensionSdkData',
        key: 'odpProducts',
      },
    },
  ],
};
