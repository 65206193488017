import axios from 'axios';
import { METAFIELD_VALUES_RESOURCE_TYPES } from '../constants/metafieldValues';

// ensure resource_type is valid.
function validateResourceType(resource_type) {
  if (!METAFIELD_VALUES_RESOURCE_TYPES.includes(resource_type)) {
    throw new Error('Invalid resource_type');
  }
}

function transformResourceTypeForURL(resource_type) {
  // pluralize resource_type for URL
  return resource_type.endsWith('s') ? resource_type : `${resource_type}s`;
}

// ensure resource_ids is valid.
function validateResourceIds(resource_type, resource_ids) {
  if (!Array.isArray(resource_ids)) {
    throw new Error('resource_ids should be an array');
  }
  switch (resource_type) {
    case 'merchant':
    case 'customer':
      // ONLY merchants & customers should have empty resource_ids
      if (resource_ids.length > 0) {
        throw new Error('resource_ids should be empty');
      }
      break;
    case 'order':
    case 'order_item':
      if (resource_ids.length !== 1) {
        throw new Error('resource_ids should contain exactly one id');
      }
    /* falls through */
    default:
      if (resource_ids.length === 0) {
        throw new Error('resource_ids should not be empty');
      }
      if (resource_ids.every((id) => typeof id !== 'string')) {
        throw new Error('resource_ids should be an array of strings');
      }
      return;
  }
}

function buildParentId(resource_type, resource_ids) {
  switch (resource_type) {
    case 'cart_item': {
      // Extract the first part of the first resource_ids
      const cartId = resource_ids[0].split('-')[0];

      // Check if all resource_ids start with the same cart_id
      const allWithSameCartId = resource_ids.every((element) =>
        element.startsWith(cartId),
      );

      if (!allWithSameCartId) {
        throw new Error('Not all resource_ids have the same cart_id');
      }

      return cartId;
    }
    default:
      break;
  }
}

function getMetafieldValuesURL(resource_type, resource_ids) {
  const resource_type_for_url = transformResourceTypeForURL(resource_type);
  switch (resource_type) {
    case 'merchant':
    case 'product':
      return `/api/${resource_type_for_url}/app_metafields`;
    case 'order':
      return `/api/${resource_type_for_url}/${resource_ids[0]}/app_metafields`;
    case 'order_item':
      return `/api/orders/${resource_ids[0]}/${resource_type_for_url}/app_metafields`;
    case 'customer':
      return `/api/users/app_metafields`;
    case 'cart_item': {
      let cartId = buildParentId(resource_type, resource_ids);
      return `/api/carts/${cartId}/${resource_type_for_url}/app_metafields`;
    }
  }
}

class MetafieldValuesService {
  static async getMetafieldValues(
    appId,
    { resource_type, resource_ids = [], keys },
  ) {
    // validate request params.
    if (!appId) {
      throw new Error('appId is required');
    }
    validateResourceType(resource_type);
    validateResourceIds(resource_type, resource_ids);
    if (!keys) {
      throw new Error('keys is required');
    }
    // call SHOP API to get metafield values.
    const params = {
      application_id: appId,
      resource_ids: resource_ids,
      keys: keys,
    };
    // include authorization_token as well if visiting order confirm page.
    const orderResourceTypes = ['order', 'order_item'].includes(resource_type);
    const isOrderConfirmPage =
      window.location.pathname.match(/\/orders\/.+/g)?.length === 1;
    if (orderResourceTypes && isOrderConfirmPage) {
      const urlParams = new URLSearchParams(window.location.search);
      const authorization_token = urlParams.get('authorization_token');
      params.authorization_token = authorization_token;
    }

    const resp = await axios
      .get(getMetafieldValuesURL(resource_type, resource_ids), {
        params,
        withCredentials: true,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
        },
      })
      .then((res) => res.data)
      .catch((err) => {
        throw err?.response?.data ?? 'Unknown error occurred.';
      });

    const response = new Map();
    for (const r of resp.items) {
      const a = response.get(r.resource_id) ?? [];
      a.push({
        namespace: r.namespace,
        key: r.key,
        field_type: r.field_type,
        field_value: r.field_value,
      });
      response.set(r.resource_id, a);
    }
    return Object.fromEntries(response);
  }
}

export default MetafieldValuesService;
