import product_list from './product_list';
import product_detail from './product_detail';
import promotion_page from './promotion_page';
import order_detail from './order_detail';
import order_confirm from './order_confirm';
import category from './category';
import cart from './cart';
import checkout from './checkout';

// Schema for all supported pages
const all = {
  functions: [
    {
      name: 'getMerchantId',
      type: 'angularData',
      options: {
        source: 'appExtensionSdkData',
        key: 'merchantId',
      },
    },
    {
      name: 'getLoadedCurrency',
      type: 'angularData',
      options: {
        source: 'appExtensionSdkData',
        key: 'loadedCurrency',
      },
    },
    {
      name: 'getCurrentPageIdentifier',
      type: 'angularData',
      options: {
        source: 'appExtensionSdkData',
        key: 'pageIdentifier',
      },
    },
    {
      name: 'getCurrentThemeKey',
      type: 'angularData',
      options: {
        source: 'appExtensionSdkData',
        key: 'themeKey',
      },
    },
    {
      name: 'getLoadedLanguage',
      type: 'angularData',
      options: {
        source: 'appExtensionSdkData',
        key: 'loadedLanguage',
      },
    },
    {
      name: 'getCookieConsent',
      type: 'angularFunc',
      options: {
        source: 'cookieConsentService',
        key: 'getAllCookieConsents',
      },
    },
    // Metafield Values
    {
      name: 'getMetafieldValues',
      type: 'nativeFunc',
      options: {
        source: 'MetafieldValuesService',
        key: 'getMetafieldValues',
        includeAppId: true,
      },
    },
  ],
  events: [
    {
      type: 'angular',
      source: '$rootScope',
      incomingEvent: 'main.currency',
      sdkEvent: 'currency.changed',
    },
    {
      type: 'angular',
      source: '$rootScope',
      incomingEvent: 'cartService.update',
      sdkEvent: 'cart.update',
    },
  ],
};

// Should use page identifier as the key
export default {
  all,
  cart,
  category,
  checkout,
  product_list,
  product_detail,
  promotion_page,
  order_detail,
  order_confirm,
};
