import { _setupFunctions } from './setupFunctions';
import functionsSchemas from './schemas';
import { cloneDeep } from 'lodash-es';
import { _setupEvents } from './setupEvents';
import MetafieldValuesService from '../../service/metafieldValuesService';

const externalServices = {};
let utilsFunctions = {};

function _groupDependencies(functionsSchema, eventsSchema) {
  const deps = functionsSchema.reduce(
    (acc, funcSchema) => {
      switch (funcSchema.type) {
        case 'angularData':
        case 'angularFunc': {
          const source = funcSchema.options?.source;
          if (source && !acc.angular.includes(source)) {
            acc.angular.push(source);
          }
          break;
        }
        case 'nativeFunc': {
          const source = funcSchema.options?.source;
          if (source && !acc.native.includes(source)) {
            acc.native.push(source);
          }
          break;
        }

        default:
          break;
      }

      return acc;
    },
    { angular: [], native: [] },
  );

  return eventsSchema.reduce((acc, eventSchema) => {
    switch (eventSchema.type) {
      case 'angular': {
        const source = eventSchema.source;
        if (source && !acc.angular.some((dep) => dep === source)) {
          acc.angular.push(source);
        }
        break;
      }

      default:
        break;
    }

    return acc;
  }, deps);
}

function _groupEvents(schema) {
  return schema.reduce((acc, { sdkEvent, ...curr }) => {
    const existingEvent = acc.find(
      (e) =>
        e.type === curr.type &&
        e.source === curr.source &&
        e.incomingEvent === curr.incomingEvent,
    );
    if (existingEvent) {
      existingEvent.sdkEvents.push(...sdkEvent);
    } else {
      acc.push({
        ...curr,
        sdkEvents: [sdkEvent],
      });
    }
    return acc;
  }, []);
}

function _getCurrentPageFunctionsSchemas(currentPage) {
  const globalSchema = cloneDeep(functionsSchemas.all);
  const pageSchema = cloneDeep(functionsSchemas[currentPage]);

  const allFunctions = [
    ...(globalSchema?.functions || []),
    ...(pageSchema?.functions || []),
  ];
  const allEvents = [
    ...(globalSchema?.events || []),
    ...(pageSchema?.events || []),
  ];

  const allDeps = _groupDependencies(allFunctions, allEvents);
  const groupedEvents = _groupEvents(allEvents);

  return {
    dependencies: allDeps,
    functions: allFunctions,
    events: groupedEvents,
  };
}

function _getAngularService(angularInjector, serviceName) {
  if (!externalServices[serviceName]) {
    externalServices[serviceName] = angularInjector.get(serviceName);
  }

  return externalServices[serviceName];
}

// Load angular dependencies for functions
function _loadAngularDependencies(angularInjector, dependencies) {
  const angularDeps = dependencies?.angular || [];

  return angularDeps.reduce((acc, currDep) => {
    acc[currDep] = _getAngularService(angularInjector, currDep);
    return acc;
  }, {});
}

// Load native js dependencies for functions.
const _loadNativeDependencies = (dependencies) => {
  const deps = {};
  for (const dep of dependencies.native) {
    switch (dep) {
      case 'MetafieldValuesService':
        deps[dep] = MetafieldValuesService;
        break;
    }
  }
  return deps;
};

function _loadDependencies(angularInjector, dependencies) {
  return {
    angular: _loadAngularDependencies(angularInjector, dependencies),
    native: _loadNativeDependencies(dependencies),
  };
}

function _initializeUtils(currentPage) {
  const currentSchema = _getCurrentPageFunctionsSchemas(currentPage);

  const angularInjector = angular.element('html').injector();

  const dependencies = _loadDependencies(
    angularInjector,
    currentSchema.dependencies,
  );
  utilsFunctions = _setupFunctions(dependencies, currentSchema.functions);
  _setupEvents(dependencies, currentSchema.events);
}

export { utilsFunctions, _initializeUtils };
