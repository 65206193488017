export default {
  functions: [
    {
      name: 'getOCPOrder',
      type: 'angularData',
      options: {
        source: 'appExtensionSdkData',
        key: 'ocpOrder',
      },
    },
    {
      name: 'getOCPProducts',
      type: 'angularData',
      options: {
        source: 'appExtensionSdkData',
        key: 'ocpProducts',
      },
    },
  ],
};
