import getObjFromKey from '../helpers/getObjFromKey';
import InitializeSdkError from '../errors/InitializeSdkError';

function _createFunctionFromData(data, key) {
  return () => {
    return getObjFromKey(data, key);
  };
}

function _createFunctionFromFunc(source, schemaOptions) {
  return (config, ...params) => {
    const key = schemaOptions?.key;

    // handle options
    if (schemaOptions?.includeAppId) {
      params.unshift(config.appId);
    }

    return getObjFromKey(source, key)(...params);
  };
}

function _getAngularSourceFromConfig(angularDeps, schema) {
  const source = angularDeps?.[schema.options?.source] || null;
  if (!source) {
    throw new InitializeSdkError(
      `Invalid source (Function Name: ${schema.name})`,
    );
  }

  return source;
}

function _getNativeSourceFromConfig(nativeDeps, schema) {
  const source = nativeDeps?.[schema.options?.source] || null;
  if (!source) {
    throw new InitializeSdkError(
      `Invalid source (Function Name: ${schema.name})`,
    );
  }
  return source;
}

function _setupFunction(dependencies, schema) {
  const angularDeps = dependencies.angular;
  const nativeDeps = dependencies.native;

  switch (schema.type) {
    case 'angularData': {
      const source = _getAngularSourceFromConfig(angularDeps, schema);
      return _createFunctionFromData(source, schema.options?.key);
    }
    case 'angularFunc': {
      const source = _getAngularSourceFromConfig(angularDeps, schema);
      return _createFunctionFromFunc(source, schema.options);
    }
    case 'nativeFunc': {
      const source = _getNativeSourceFromConfig(nativeDeps, schema);
      return _createFunctionFromFunc(source, schema.options);
    }
    default:
      return null;
  }
}

function _setupFunctions(dependencies, schema) {
  if (!schema) return {};

  return (schema || []).reduce((acc, funcSchema) => {
    const func = _setupFunction(dependencies, funcSchema);
    if (func) {
      acc[funcSchema.name] = func;
    }

    return acc;
  }, {});
}

export { _setupFunctions };
